<template>
 <div>
  <v-dialog
    v-model="dialog"
    max-width="700"
    scrollable
  >
    <v-card>
      <v-card-title>
        Agregar nuevo descuento
        <v-spacer></v-spacer>
        <v-btn title="Cerrar" icon @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form @submit.prevent="agregarAlista">
          <v-row class="pt-2">
            <v-col cols="6" md="2" class="">
              Código
              <v-text-field
                v-model.trim="filtro.codigo"
                type="number"
                :readonly="datos.editar"
                :filled="datos.editar"
                hide-details
                outlined
                dense
                @blur="rellenarCampos(null)"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" md="4" class="">
              Nombre
              <v-text-field
                v-model.trim="filtro.nombre"
                :readonly="true"
                :filled="true"
                hide-details
                outlined
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" md="3">
              Tipo Descuento
              <v-text-field
                v-model.trim="filtro.tipoDescuento"
                :readonly="true"
                :filled="true"
                hide-details
                outlined
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" md="3">
              Importe
              <TextFieldMoneyVue
                v-model="filtro.importe"
                outlined
                dense
                v-bind:properties="{
                  prefix: '$',
                  'hide-details': true,
                  'validate-on-blur': true
                }"
              />
            </v-col>
          </v-row>
          <v-row class="d-flex justify-end">
            <v-col cols="12" class="d-flex justify-end">
              <v-btn
                class="mr-2"
                color="info"
                type="submit"
                :disabled="!filtro.codigo"
                v-if="datos.editar == false"
              >
                Agregar
              </v-btn>
              <v-btn
                class="mr-2"
                color="info"
                type="submit"
                v-if="datos.editar"
              >
                Actualizar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
  <!-- componentes -->
  <ModalElegirDescuentoPosVue
    v-model="dialogG"
    :datos="datos.descuentos"
    @traerDatos="rellenarCampos"
  />
 </div>
</template>

<script>
import TextFieldMoneyVue from '../util/TextFieldMoney.vue'
import ModalElegirDescuentoPosVue from './ModalElegirDescuentoPos.vue'

export default {
  name: 'ModalAgregarDescuento',
  data(){
    return{
      dialogG: false,
      filtro: {
        codigo: null,
        nombre: null,
        tipoDescuento: null,
        tipoDescuentoCodigo: null,
        importe: null
      }
    }
  },
  props:{
    value: Boolean,
    datos:{
      type: Object,
      default: {}
    },
  },
  methods:{
    rellenarCampos(item){
      if(item){
        this.filtro.codigo = item.descuento_id;
        this.dialogG = false;
      }
      if(this.filtro.codigo){
        let aux = this.datos.descuentos.filter(elem => elem.descuento_id == this.filtro.codigo)
        if(aux.length == 1){
          this.filtro.nombre = aux[0].descuento_nombre;
          this.filtro.tipoDescuento = aux[0].tipo_descuento_nombre;
          this.filtro.tipoDescuentoCodigo = aux[0].tipo_descuento_id;
        }
        if(aux.length == 0){
          this.dialogG = true;
          //abre el modal grande
        }
      }else{
        this.dialogG = true;
        this.filtro= {
          codigo: null,
          nombre: null,
          tipoDescuento: null,
          tipoDescuentoCodigo: null,
          importe: null
        }
      }
    },
    agregarAlista(){
      if(!this.datos.editar){
        if(this.filtro.importe){
          this.filtro.codigo = parseInt(this.filtro.codigo)
          this.$emit('agregarNvo',this.filtro)
        }else{
          this.$swal.fire({
            icon: 'error',
            title: `Datos insuficientes`,
            text: 'Debe ingresarse un importe.'
          })
        }
      }else{
        this.filtro.codigo = parseInt(this.filtro.codigo)
        this.$emit('editar_desc',this.filtro)
      }
    }
  },
  computed:{
    dialog:{
      get(){
        return this.value
      },
      set(value){
        this.$emit('input', value)
      }
    }
  },
  components:{
    TextFieldMoneyVue, ModalElegirDescuentoPosVue
  },
  watch:{
    dialog(val){
      if(!val){
        this.filtro= {
          codigo: null,
          nombre: null,
          tipoDescuento: null,
          tipoDescuentoCodigo: null,
          importe: null
        }
      }else{
        if(this.datos.editar){
          this.filtro = {
            codigo: this.datos.item.descuento_id,
            nombre: this.datos.item.descuento_nombre,
            tipoDescuento: this.datos.item.tipo_descuento_nombre,
            tipoDescuentoCodigo: this.datos.item.tipo_descuento_id,
            importe: this.datos.item.importe
          }
        }
      }
    }
  }
}
</script>

<style>

</style>