<template>
<div>
  <v-dialog
    v-model="dialog"
    max-width="800"
    scrollable
  >
    <v-card>
      <v-card-title>
        <span>
          Datos del Pedido
        </span>
        <v-spacer></v-spacer>
        <v-btn title="Cerrar" icon @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-spacer></v-spacer>
      <v-card-text class="pt-6">
        <v-row>
          <v-col cols="12" md="5">
            <v-row>
              <v-col cols="6">
                Tipo de Pedido
                <v-text-field
                  v-model.trim="datos.item.tipo_pedido_nombre"
                  readonly
                  filled
                  hide-details
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                Forma de Pago
                <v-text-field
                  v-model.trim="datos.item.forma_pago_nombre"
                  readonly
                  filled
                  hide-details
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="7">
            <v-row>
              <v-col cols="4">
                Num. Pedido
                <v-text-field
                  v-model.trim="datos.item.numero_pedido"
                  readonly
                  filled
                  hide-details
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                Fecha
                <v-text-field
                  v-model.trim="datos.item.fecha"
                  readonly
                  filled
                  hide-details
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                Importe
                <TextFieldMoneyVue
                  v-model="datos.item.importe"
                  outlined
                  dense
                  v-bind:properties="{
                    prefix: '$',
                    'hide-details': true,
                    'validate-on-blur': true,
                    'readonly': true,
                    'filled': true
                  }"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="4">
                Marca
                <v-text-field
                  v-model.trim="datos.item.marca"
                  readonly
                  filled
                  hide-details
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                Tar. Nro
                <v-text-field
                  v-model.trim="datos.item.tarjeta_numero"
                  readonly
                  filled
                  hide-details
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                cuotas
                <v-text-field
                  v-model.trim="datos.item.cuotas"
                  readonly
                  filled
                  hide-details
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="4">
                Cupón
                <v-text-field
                  v-model.trim="datos.item.cupon"
                  readonly
                  filled
                  hide-details
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                Lote
                <v-text-field
                  v-model.trim="datos.item.lote"
                  readonly
                  filled
                  hide-details
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                Cod. Autor.
                <v-text-field
                  v-model.trim="datos.item.codigo_autorizacion"
                  readonly
                  filled
                  hide-details
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="2" class="py-0">
            Sucursal
            <v-text-field
              v-model.trim="datos.datos_caja.sucursal_nombre"
              readonly
              filled
              hide-details
              outlined
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="2" class="py-0">
            Cód. Vendedor
            <v-text-field
              v-model.trim="datos.datos_caja.vendedor_codigo"
              readonly
              filled
              hide-details
              outlined
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            Nombre Vendedor
            <v-text-field
              v-model.trim="datos.datos_caja.vendedor_nombre"
              readonly
              filled
              hide-details
              outlined
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="2" class="py-0">
            Nro. Caja
            <v-text-field
              v-model.trim="datos.datos_caja.caja_codigo"
              readonly
              filled
              hide-details
              outlined
              dense
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="5" class="py-0">
            Nombre Caja
            <v-text-field
              v-model.trim="datos.datos_caja.caja_jerarquia"
              readonly
              filled
              hide-details
              outlined
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="5" class="py-0">
            Comprobante
            <v-text-field
              v-model.trim="datos.datos_caja.descripcion"
              readonly
              filled
              hide-details
              outlined
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="2" class="py-0">
            Imp. Comp.
            <TextFieldMoneyVue
              v-model="datos.datos_caja.importe"
              outlined
              dense
              v-bind:properties="{
                prefix: '$',
                'hide-details': true,
                'validate-on-blur': true,
                'readonly': true,
                'filled': true
              }"
            />
          </v-col>
        </v-row>

      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          class="mr-2"
          color="secondary"
          @click="dialog = false"
        >
          Volver
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</div>
</template>

<script>
import TextFieldMoneyVue from '../../components/util/TextFieldMoney.vue';

export default {
  name: 'ModalDatosPedido',
  data(){
    return{

    }
  },
  props:{
    value: Boolean,
    datos: {
      type: Object,
      default: {
        
      }
    },
  },
  methods:{
    iniciarModal(){

    }
  },
  computed:{
    dialog:{
      get(){
        return this.value
      },
      set(value){
        this.$emit('input', value)
      }
    },
  },
  components:{
    TextFieldMoneyVue
  },
  watch:{
    dialog(val){
      if(val){
        this.iniciarModal();
        //hacer copias del acu y del pendiente
      }else{
        /*this.movimientos=[];
        this.totalAcumulado = this.totalAcuCopia;
        this.pendiente = this.pendienteCopia;
        this.movimientos_a = JSON.parse(JSON.stringify(this.movimientos_aCopia));*/
      }
    }
  }
}
</script>

<style>

</style>